import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Mutation, Query} from 'react-apollo';

import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import HistoryTable from './HistoryTable';
import {ROUTES_LOGGED_IN, MENU_ITEM_ROUTES} from '../../const';
import {GET_CURRENT_USER} from '../SkillEntryForm';

export const SET_DEFAULT_VIEW = gql`
  mutation setDefaultView($input: SetDefaultViewInput!) {
    setDefaultView(input: $input)
  }
`;

const UserSettings = () => {
  return (
    <Query query={GET_CURRENT_USER}>
      {({data = {}, loading, error}) => {
        const {
          currentUser: {_id}
        } = data;

        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;
        return (
          <Mutation mutation={SET_DEFAULT_VIEW}>
            {setDefaultView => {
              const options = ROUTES_LOGGED_IN.filter(item => MENU_ITEM_ROUTES.includes(item.name)).map((skill, i) => {
                return {
                  key: i,
                  text: skill.name,
                  value: skill.pathName
                };
              });

              return (
                <div className="user-settings">
                  <div>
                    <span className="user-settings-label">You can choose default view of this app</span>
                    <Dropdown
                      placeholder="Select default of app view"
                      options={options}
                      selection
                      onChange={(e, {value}) => {
                        setDefaultView({
                          variables: {
                            input: {
                              defaultView: value,
                              _id
                            }
                          }
                        });
                      }}
                    />
                  </div>
                  <HistoryTable />
                </div>
              );
            }}
          </Mutation>
        );
      }}
    </Query>
  );
};
export default UserSettings;
