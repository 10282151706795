import React from 'react';

import {Table} from 'semantic-ui-react';

const Header = ({title = '', headerRows = [], menu: Menu = null, handleSort, column, direction}) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan={headerRows.length} textAlign="center" className="table-header">
          {title}
          {Menu && <Menu />}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        {headerRows.map(({label, sortBy}, i) => (
          <Table.HeaderCell key={i} sorted={column === sortBy ? direction : null} onClick={handleSort(sortBy)}>
            {label}
          </Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};

export default Header;
