import React from 'react';
import ReactDOM from 'react-dom';
import ApolloClient from 'apollo-client';
import {ApolloProvider} from 'react-apollo';
import {HttpLink} from 'apollo-link-http';
import {setContext} from 'apollo-link-context';
import {InMemoryCache} from 'apollo-cache-inmemory';
import {BrowserRouter} from 'react-router-dom';

import {uri} from './config';
import App from './components/App';

const cache = new InMemoryCache();

const authLink = setContext((_, {headers}) => {
  const userToken = localStorage.getItem('userToken');

  return {
    headers: {
      ...headers,
      authorization: userToken ? `Bearer ${userToken}` : ''
    }
  };
});

const httpLink = new HttpLink({
  uri
});
const client = new ApolloClient({
  cache,
  link: authLink.concat(httpLink)
});

window.addEventListener('load', () => {
  const root = document.getElementById('root');
  ReactDOM.render(
    <ApolloProvider client={client}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </ApolloProvider>,
    root
  );
});
