import React, {Component} from 'react';
import {Table as SemanticTable} from 'semantic-ui-react';
import sortBy from 'lodash.sortby';

import Header from './Header';
import Row from './Row';

export default class Table extends Component {
  state = {
    column: null,
    direction: 'ascending'
  };

  handleSort = clickedColumn => () => {
    const {column, direction} = this.state;

    if (column !== clickedColumn) {
      this.setState({
        column: clickedColumn,
        direction: 'ascending'
      });

      return;
    }

    this.setState({
      direction: direction === 'ascending' ? 'descending' : 'ascending'
    });
  };

  render() {
    const {headerRows, title, menu, currentUser, className, rows} = this.props;
    const {column, direction} = this.state;
    const sortedData = direction === 'ascending' ? sortBy(rows, [column]) : sortBy(rows, [column]).reverse();

    return (
      <div className="table-container">
        <SemanticTable inverted sortable size="large" className={`table ${className}`} celled>
          <Header
            title={title}
            headerRows={headerRows}
            menu={menu}
            handleSort={this.handleSort}
            column={column}
            direction={direction}
          />
          <SemanticTable.Body>
            {sortedData.map((row, index) => (
              <Row key={index} {...row} currentUser={currentUser} />
            ))}
          </SemanticTable.Body>
        </SemanticTable>
      </div>
    );
  }
}
