import React from 'react';

import {SemanticToastContainer, toast} from 'react-semantic-toasts';

export const Toast = () => {
  return <SemanticToastContainer position="bottom-center" />;
};

export const toastSucces = description => {
  return toast({
    type: 'success',
    title: 'Success',
    description,
    animation: 'bounce'
  });
};

export const toastError = description => {
  return toast({
    type: 'error',
    title: 'Error',
    description,
    animation: 'bounce'
  });
};
