import React from 'react';
import {Loader as SemanicLoader, Dimmer} from 'semantic-ui-react';

const Loader = () => {
  return (
    <Dimmer active>
      <SemanicLoader>Loading</SemanicLoader>
    </Dimmer>
  );
};

export default Loader;
