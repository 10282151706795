export const ratingLabels = ['Awareness', 'Beginner', 'Intermediate', 'Advanced', 'Expert'];
export const likingLabels = ['I hate it', "I don't like it", 'I neither like nor dislike', 'I like it', 'I love it'];
export const ratingDescriptions = [
  "I know where I can find information to develop my skills. Probably it isn't such a big deal to start play around this technology. If somebody give me a motivation I will jump dive into in it.",
  "I know the basics. I've never worked in bussines project.I need a help somebody to work with it in the project, but I belive I can do it. Probably, after a short time I can work without any help.",
  "I've worked with this technology in professional project. I can easly work without any help in the project.",
  'I have many projects in this technology behind me. I can easly lead any project with this technology.',
  'I can easly lead any project with this technology. I would have an impact for develop this technology. There are no secrets befor me.'
];
export const likingDescriptions = [
  "I realy don't want work with this technology. It can take too much time to solve even simple issue. Complecated and very hard to use. It's give me a headache.",
  "I can work with this technology, but i don't want to. It don't give me too much fun. Only big amount of money or high pressure can encourage me.",
  "This technology it's ok. Not so hard to use. Maybe it don't give me too much fun, but also don't give me to much problems. Average for me.",
  "This technology can give me a lot satisfaction. I feel it's a future. For me it's simple to use and make me smile.",
  "This technology is one of my favorite. I can work with every day. Definitely i feel it's a future."
];
