import React from 'react';
import {withRouter} from 'react-router-dom';
import {Menu} from 'semantic-ui-react';

import {ROUTES, ROUTES_LOGGED_IN, ROUTES_LOGGED_OUT, MENU_ITEM_ROUTES} from '../../const';
import {toastError} from '../common/Toast';

const Dashboard = ({location, history, user}) => {
  const handleItemClick = (e, {name}) => {
    const route = ROUTES.find(route => route.name === name);
    if (route) {
      history.push(route.pathName);
    } else {
      toastError('Wrong way');
    }
  };
  const {pathname} = location;
  return (
    <Menu inverted secondary className="dashboard">
      {!user
        ? ROUTES_LOGGED_OUT.map(({name, pathName}) => (
            <Menu.Item
              key={name}
              name={name}
              active={pathname === pathName}
              onClick={handleItemClick}
              className="dashboard-button"
            />
          ))
        : ROUTES_LOGGED_IN.filter(item => MENU_ITEM_ROUTES.includes(item.name)).map(({name, pathName}) => (
            <Menu.Item
              key={name}
              name={name}
              active={pathname === pathName}
              onClick={handleItemClick}
              className="dashboard-button"
            />
          ))}
    </Menu>
  );
};

export default withRouter(Dashboard);
