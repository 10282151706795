import React, {Component, Fragment} from 'react';
import {Table as SemanticTable, Button} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Query, Mutation} from 'react-apollo';
import {toast} from 'react-semantic-toasts';
import uniqBy from 'lodash/uniqBy';

import ErrorPage from '../../common/ErrorPage';
import Loader from '../../common/Loader';
import Header from './Header';
import Row from './Row';
import {Toast} from '../../common/Toast';
import {GET_SKILL_ENTRIES} from '../../UserSettings/HistoryTable';
import {GET_ALL_SKILLS} from '../MenuSkills';
import Legend from '../../Legend';

const GET_ALL_SKILL_ENTRIES = gql`
  query {
    currentUser {
      _id
      latestSkillEntries {
        knowledge
        liking
        skill {
          _id
          name
        }
      }
    }
  }
`;

const CREATE_MANY_SKILL_ENTRIES = gql`
  mutation createSkillEntryMutataion($input: [CreateSkillEntryInput!]!) {
    createManySkillEntries(input: $input)
  }
`;

export default class Table extends Component {
  state = {
    ratings: []
  };

  handleRating = ratedSkill => {
    this.setState(state => ({
      ratings: state.ratings.filter(rating => rating.name !== ratedSkill.name).concat(ratedSkill)
    }));
  };

  render() {
    const {title} = this.props;
    const headerRows = [{label: 'Name'}, {label: 'Knowledge'}, {label: 'Liking'}];

    return (
      <Query query={GET_ALL_SKILL_ENTRIES}>
        {({data = {}, loading, error}) => {
          if (loading) return <Loader />;
          if (error) return <ErrorPage text={error.message} />;

          const {currentUser = {}} = data;

          const {latestSkillEntries, _id: userId} = currentUser;

          const latestSkills = latestSkillEntries.map(({knowledge, liking, skill: {name = '', _id: skillId}}) => ({
            knowledge,
            liking,
            name,
            userId,
            skillId
          }));

          return (
            <Query query={GET_ALL_SKILLS}>
              {({data = {}, loading, error}) => {
                if (loading) return <Loader />;
                if (error) return <ErrorPage text={error.message} />;
                const {ratings} = this.state;
                const A = ratings;
                const B = latestSkills;
                const C = data.skills.map(({name, _id}) => ({name, skillId: _id, userId}));
                const rows = uniqBy([...A, ...B, ...C], 'skillId')
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map(({name, knowledge, liking, userId, skillId}) => {
                    return {
                      label: name,
                      knowledge,
                      liking,
                      userId,
                      skillId
                    };
                  });

                return (
                  <Fragment>
                    <div className="table-container  add-skills-table-container ">
                      <SemanticTable inverted sortable size="large" celled className="add-skills-table">
                        <Header title={title} headerRows={headerRows} />
                        <SemanticTable.Body>
                          {rows.map((row, index) => (
                            <Row key={index} {...row} handleRating={this.handleRating} />
                          ))}
                        </SemanticTable.Body>
                      </SemanticTable>
                      <Legend />
                    </div>
                    <Mutation mutation={CREATE_MANY_SKILL_ENTRIES} refetchQueries={() => [{query: GET_SKILL_ENTRIES}]}>
                      {(createManySkillEntries, {loading, error}) => {
                        const {message} = error || '';

                        if (loading) return <Loader />;
                        if (error) return <ErrorPage text={message} />;
                        return (
                          <Button
                            onClick={async () => {
                              const incompleteRatings = ratings.filter(({liking, knowledge}) => !liking || !knowledge);

                              if (incompleteRatings.length === 0 && ratings.length !== 0) {
                                const {
                                  data: {createManySkillEntries: isCreated}
                                } = await createManySkillEntries({
                                  variables: {
                                    input: ratings.map(({knowledge, liking, skillId, userId}) => ({
                                      knowledge,
                                      liking,
                                      skillId,
                                      userId
                                    }))
                                  }
                                });
                                if (isCreated) {
                                  toast({
                                    description: `Skills have been added`,
                                    time: 3000
                                  });
                                } else {
                                  toast({
                                    description: `Something went wrong`,
                                    time: 3000
                                  });
                                }
                              } else {
                                const myDescription = incompleteRatings.map(({name}) => `${name}`).join(' | ');

                                toast({
                                  description: `PLEASE COMPLETE FIELDS: ${myDescription}`,
                                  time: 5000
                                });
                              }
                            }}
                            secondary
                          >
                            <Toast />
                            Update skills
                          </Button>
                        );
                      }}
                    </Mutation>
                  </Fragment>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}
