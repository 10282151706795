import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import Table from '../common/Table';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import MenuSkills from './MenuSkills';
import AddSkill from './AddSkill';
import {GET_CURRENT_USER} from '../SkillEntryForm';

export const GET_SKILL = gql`
  query($skillId: ID!) {
    skill(_id: $skillId) {
      name
      latestSkillEntries {
        knowledge
        liking
        creationDatetime
        user {
          firstName
          lastName
          _id
        }
      }
    }
  }
`;

const headerRows = [
  {label: 'Name', sortBy: 'label'},
  {label: 'Knowledge', sortBy: 'knowledge'},
  {label: 'Liking', sortBy: 'liking'}
];
const SkillTable = ({
  match: {
    params: {skillId}
  }
}) => {
  return (
    <Query query={GET_CURRENT_USER}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {currentUser: {_id: currentUserId} = {}} = data;

        return (
          <Query query={GET_SKILL} variables={{skillId}}>
            {({data = {}, loading, error}) => {
              if (loading) return <Loader />;
              if (error) return <ErrorPage text={`${error.message} [${skillId}]`} />;

              const {skill: {name, latestSkillEntries} = {}} = data;

              const [currentUserDetails] = latestSkillEntries
                .map(({user, knowledge, liking}) => ({_id: user._id, knowledge, liking}))
                .filter(({_id}) => _id === currentUserId);

              const {knowledge = 1, liking = 3} = currentUserDetails || {};

              const rows = latestSkillEntries
                .filter(({user}) => user)
                .map(({user, knowledge, liking}) => ({
                  knowledge,
                  liking,
                  label: `${user.firstName} ${user.lastName}`,
                  endPoint: `/user/${user._id}`
                }));

              return (
                <div>
                  <AddSkill
                    skillId={skillId}
                    name={name}
                    initialKnowledge={knowledge}
                    initialLiking={liking}
                    hasSkill={!!currentUserDetails}
                  />
                  <Table rows={rows} title={name} headerRows={headerRows} menu={MenuSkills} />;
                </div>
              );
            }}
          </Query>
        );
      }}
    </Query>
  );
};

export default SkillTable;
