import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import Table from '../common/Table';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';

export const GET_SKILLS = gql`
  query {
    skills {
      name
      _id
      latestSkillEntries {
        knowledge
        liking
      }
    }
  }
`;

const headerRows = [
  {label: 'Name', sortBy: 'label'},
  {label: 'Average Knowledge', sortBy: 'knowledge'},
  {label: 'Average Liking', sortBy: 'liking'}
];

const CompanySkillsTable = () => {
  return (
    <Query query={GET_SKILLS}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {skills = []} = data;
        const rows = skills.map(({_id, name, latestSkillEntries}) => {
          const [knowledgeSum, likingSum] = latestSkillEntries.reduce(
            (acc, current) => {
              return [acc[0] + current.knowledge, acc[1] + current.liking];
            },
            [0, 0]
          );
          const numEntries = latestSkillEntries.length;

          const knowledgeAvg = numEntries ? Math.round(knowledgeSum / numEntries) : 0;
          const likingAvg = numEntries ? Math.round(likingSum / numEntries) : 0;

          return {knowledge: knowledgeAvg, liking: likingAvg, label: name, endPoint: `/skill/${_id}`};
        });

        return <Table rows={rows} title="Company Skills" headerRows={headerRows} />;
      }}
    </Query>
  );
};

export default CompanySkillsTable;
