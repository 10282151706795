import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';
import moment from 'moment';

import Table from '../common/Table';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';

export const GET_SKILL_ENTRIES = gql`
  query {
    currentUser {
      _id
      firstName
      lastName
      skillEntries {
        skill {
          _id
          name
        }
        _id
        creationDatetime
        knowledge
        liking
      }
    }
  }
`;

const headerRows = [
  {label: 'Name', sortBy: 'label'},
  {label: 'Knowledge', sortBy: 'knowledge'},
  {label: 'Liking', sortBy: 'liking'},
  {label: 'date', sortBy: 'creationDatetime'}
];
const HistoryTable = () => {
  return (
    <Query query={GET_SKILL_ENTRIES}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {
          currentUser: {skillEntries}
        } = data;

        const rows = skillEntries
          .sort(({creationDatetime: dt1}, {creationDatetime: dt2}) => (dt1 === dt2 ? 0 : dt1 < dt2 ? 1 : -1))
          .map(({skill: {name}, creationDatetime, knowledge, liking, _id: skillEntryId}) => {
            return {
              knowledge,
              liking,
              skillEntryId,
              label: name,
              creationDatetime: moment(creationDatetime).format('L LTS')
            };
          });

        return <Table rows={rows} title="History of skill entries" headerRows={headerRows} />;
      }}
    </Query>
  );
};

export default HistoryTable;
