import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import Table from '../common/Table';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';

export const GET_USERS = gql`
  query {
    users {
      firstName
      lastName
      _id
    }
    currentUser {
      _id
      roles
    }
  }
`;

const headerRows = [{label: 'Name', sortBy: 'label'}];

const CompanyUsersTable = () => {
  return (
    <Query query={GET_USERS}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;

        const {users = [], currentUser = {}} = data;

        const rows = users.map(({firstName, lastName, _id}) => ({
          label: `${firstName} ${lastName}`,
          endPoint: `/user/${_id}`,
          hasRated: false
        }));

        return (
          <Table
            rows={rows}
            title="Users"
            headerRows={headerRows}
            currentUser={currentUser}
            className="company-users-table"
          />
        );
      }}
    </Query>
  );
};

export default CompanyUsersTable;
