import React from 'react';

import {Table} from 'semantic-ui-react';

const Header = ({title, headerRows = []}) => {
  return (
    <Table.Header>
      <Table.Row>
        <Table.HeaderCell colSpan={headerRows.length} textAlign="center" className="table-header">
          {title}
        </Table.HeaderCell>
      </Table.Row>
      <Table.Row>
        {headerRows.map(({label}) => (
          <Table.HeaderCell key={label}>{label}</Table.HeaderCell>
        ))}
      </Table.Row>
    </Table.Header>
  );
};

export default Header;
