import React from 'react';
import {Icon} from 'semantic-ui-react';

export default function ErrorPage({text}) {
  return (
    <div className="error-page">
      <Icon name="remove circle" size="large" />
      {text}
    </div>
  );
}
