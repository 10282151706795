import React from 'react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import Table from '../common/Table';
import ErrorPage from '../common/ErrorPage';
import Loader from '../common/Loader';
import MenuUsers from './MenuUsers';

export const GET_USER = gql`
  query($userId: ID!) {
    user(_id: $userId) {
      firstName
      lastName

      latestSkillEntries {
        knowledge
        liking
        skill {
          name
          _id
        }
      }
    }
  }
`;

const headerRows = [
  {label: 'Skills', sortBy: 'label'},
  {label: 'Knowledge', sortBy: 'knowledge'},
  {label: 'Liking', sortBy: 'liking'}
];

const UserTable = ({
  match: {
    params: {userId}
  }
}) => {
  return (
    <Query query={GET_USER} variables={{userId}}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <ErrorPage text={error.message} />;
        const {user = {}} = data;

        const {latestSkillEntries, firstName = '', lastName = ''} = user;
        const rows = latestSkillEntries.map(({knowledge, liking, skill: {name = '', _id}}) => ({
          knowledge,
          liking,
          label: name,
          endPoint: `/skill/${_id}`
        }));

        return <Table rows={rows} title={`${firstName} ${lastName}`} headerRows={headerRows} menu={MenuUsers} />;
      }}
    </Query>
  );
};

export default UserTable;
