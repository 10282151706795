import React from 'react';
import {Dropdown} from 'semantic-ui-react';
import gql from 'graphql-tag';
import {Query} from 'react-apollo';

import Loader from '../common/Loader';

export const GET_SKILLS = gql`
  query($userId: ID!) {
    user(_id: $userId) {
      firstName
      lastName

      latestSkillEntries {
        skill {
          name
          _id
        }
      }
    }
  }
`;

const MenuSkills = ({userId, onSetSkillId}) => {
  return (
    <Query query={GET_SKILLS} variables={{userId}}>
      {({data = {}, loading, error}) => {
        if (loading) return <Loader />;
        if (error) return <div />;
        const {
          user: {latestSkillEntries}
        } = data;

        const options = latestSkillEntries.map(({skill}, i) => {
          return {
            key: i,
            text: skill.name,
            value: skill._id
          };
        });

        return (
          <span className="header-select">
            <Dropdown
              button
              className="icon"
              floating
              labeled
              icon="address card"
              search
              options={options}
              text="Select skill"
              onChange={(e, {value}) => {
                onSetSkillId(value);
              }}
            />
          </span>
        );
      }}
    </Query>
  );
};
export default MenuSkills;
