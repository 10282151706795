import classNames from 'classnames';
import React, {useState} from 'react';
import {Popup} from 'semantic-ui-react';
import genRange from 'lodash/range';

function RatingIcon({active, value, onRate, ...props}) {
  return (
    <i
      {...props}
      onClick={e => onRate(e, {rating: value})}
      tabIndex="0"
      aria-checked={active}
      aria-posinset={value}
      aria-setsize={value}
      className={classNames('icon', {active})}
      role="radio"
    />
  );
}

export default function Rating({
  icon,
  size,
  onRate,
  range: [startRange, endRange] = [1, 5],
  descriptions = [],
  rating
}) {
  const [active, setActiveStar] = useState(undefined);
  const range = genRange(startRange, endRange + 1);
  return (
    <div className={`ui ${size} ${icon} rating`} role="radiogroup" tabIndex="-1">
      {range.map((value, index) => (
        <Popup
          key={value}
          trigger={
            <RatingIcon
              active={(active || rating) >= value && 'active'}
              value={value}
              onRate={onRate}
              onMouseEnter={() => setActiveStar(value)}
              onMouseLeave={() => setActiveStar(undefined)}
            />
          }
          content={descriptions[index]}
        />
      ))}
    </div>
  );
}
