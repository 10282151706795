import React, {Fragment} from 'react';
import {Table, Rating, Icon} from 'semantic-ui-react';
import {Link} from 'react-router-dom';
import gql from 'graphql-tag';
import {Mutation} from 'react-apollo';
import moment from 'moment';

import {USERS_ADMIN, hasRole} from '../../../utils/roles';
import {GET_USERS} from '../../CompanyUsersTable';
import {GET_SKILL_ENTRIES} from '../../UserSettings/HistoryTable';
import {toastSucces, toastError} from '../../common/Toast';

const DELETE_USER = gql`
  mutation deleteUser($id: ID!) {
    deleteUser(_id: $id)
  }
`;

const DELETE_SKILL_ENTRY = gql`
  mutation deleteSkillEntry($id: ID!) {
    deleteSkillEntry(_id: $id)
  }
`;

const Row = ({
  knowledge,
  liking,
  label,
  endPoint = '/',
  hasRated = true,
  currentUser = {},
  skillEntryId,
  creationDatetime = null
}) => {
  const [, , id] = endPoint.split('/');
  const isToday = moment(creationDatetime, 'MM-DD-YYYY').isSame(new Date(), 'day');

  return (
    <Mutation mutation={DELETE_USER} refetchQueries={() => [{query: GET_USERS}]}>
      {deleteUser => {
        return (
          <Table.Row>
            <Table.Cell>
              {endPoint !== '/' ? (
                <Link className="table-links" to={endPoint}>
                  {label}
                </Link>
              ) : (
                <span>{label}</span>
              )}

              {hasRole(currentUser, USERS_ADMIN) && (
                <Icon
                  name="delete"
                  className="table-delete"
                  onClick={async () => {
                    const {data: {deleteUser: isDeletedUser} = {}} = await deleteUser({
                      variables: {id}
                    });

                    if (isDeletedUser) {
                      toastSucces('User has been deleted');
                    } else {
                      toastError('User has not been deleted');
                    }
                  }}
                />
              )}
            </Table.Cell>
            {hasRated && (
              <Fragment>
                <Table.Cell>
                  <Rating icon="star" rating={knowledge} maxRating={5} disabled />
                </Table.Cell>
                <Table.Cell>
                  <Rating icon="heart" rating={liking} maxRating={5} disabled />
                </Table.Cell>
              </Fragment>
            )}
            {creationDatetime && (
              <Table.Cell>
                {creationDatetime}
                <Mutation mutation={DELETE_SKILL_ENTRY} refetchQueries={() => [{query: GET_SKILL_ENTRIES}]}>
                  {deleteSkillEntry => {
                    return (
                      <Fragment>
                        {isToday && (
                          <Icon
                            name="delete"
                            className="table-delete"
                            onClick={async () => {
                              const {data: {deleteSkillEntry: isDeleteSkillEntry} = {}} = await deleteSkillEntry({
                                variables: {id: skillEntryId}
                              });

                              if (isDeleteSkillEntry) {
                                toastSucces('SkillEntry has been deleted');
                              } else {
                                toastError('SkillEntry has not been deleted');
                              }
                            }}
                          />
                        )}
                      </Fragment>
                    );
                  }}
                </Mutation>
              </Table.Cell>
            )}
          </Table.Row>
        );
      }}
    </Mutation>
  );
};

export default Row;
